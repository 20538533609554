import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'
import { Table, Input, Form, Select } from 'antd'
import './EditableTable.css'
import { genderList, validateFullBankAccount, validator } from '../../../helper'
import { adminValidationErrors, errors } from '../../../translates/errors'
import { profileScreen } from '../../../translates'

const { Option } = Select
const bankBikName = profileScreen.input.bankBik.name
const bankAccountName = profileScreen.input.bankAccount.name
const gender = profileScreen.input.gender.name

const EditableContext = React.createContext(null)

const EditableRow = props => {
  const [form] = Form.useForm()
  if (props['data-row-key'] === bankBikName) {
    return null
  }
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = props => {
  let childNode = <EditableCellValue {...props} />
  let childNodeAdded = null
  if (props.record?.key === bankAccountName && props.dataSource && props.dataIndex !== 'action') {
    const editedProps = {
      ...props,
      record: props.dataSource.filter(item => item.key === bankBikName)[0],
      isAddedNode: true
    }
    childNodeAdded = <EditableCellValue {...editedProps} />
  }
  if (props.record?.key === gender && props.dataSource && props.dataIndex === 'newValue') {
    const editedProps = {
      ...props,
      renderItem: (
        <Select
          placeholder={profileScreen.input.gender.placeholder}
          defaultValue={props.record?.newValue}
          className="edit-form gender"
        >
          {genderList.map(c => (
            <Option key={c.label} value={c.value}>
              {c.label}
            </Option>
          ))}
        </Select>
      )
    }
    childNode = <EditableCellValue {...editedProps} />
  }
  if (props.record?.key === gender && props.dataSource && props.dataIndex === 'oldValue') {
    const editedProps = {
      ...props,
      renderItem: <div>{genderList.find(g => g.value === props.record?.oldValue)?.label}</div>
    }
    childNode = <EditableCellValue {...editedProps} />
  }
  return (
    <td title={props.title}>
      {childNodeAdded && !props.editable ? (
        <>
          <div className="cell-value-wrap">{childNode}</div>
          <div className="cell-value-wrap">{childNodeAdded}</div>
        </>
      ) : childNodeAdded ? (
        <>
          {childNode}
          {childNodeAdded}
        </>
      ) : (
        <>{childNode}</>
      )}
    </td>
  )
}

const EditableCellValue = ({
  title,
  initEditable,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setHasErrors,
  backendFieldsErrors = {},
  isAddedNode = false,
  renderItem,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([])
  const inputRef = useRef(null)
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editable && initEditable && !record?.disableEdit) {
      toggleEdit()
      // внутри toggleEdit() работает input.focus() через момент вызовем blur() чтобы провалидировался input
      setTimeout(() => inputRef.current?.blur(), 300)
    }
  }, [initEditable, editable, record?.disableEdit])

  const dataIndexPostfix = useMemo(() => {
    return `${dataIndex}${isAddedNode ? 'Added' : ''}`
  }, [dataIndex, isAddedNode])

  const bikValue = useMemo(() => {
    return restProps?.dataSource?.filter(item => item?.key === bankBikName)[0]?.newValue
  }, [restProps])

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      const formErr = form.getFieldsError([dataIndexPostfix])
      if (fieldErrors.length || formErr?.[0]?.errors?.length) {
        setHasErrors(record?.key, true)
        form.setFields([
          {
            name: dataIndexPostfix,
            errors: [...fieldErrors, ...(formErr?.[0]?.errors?.length ? formErr[0].errors : [])]
          }
        ])
      } else {
        setHasErrors(record?.key, false)
      }
    }
  }, [fieldErrors, dataIndex, editing, record, dataIndexPostfix])

  useEffect(() => {
    if (backendFieldsErrors?.[record?.key]?.length > 0) {
      setFieldErrors(backendFieldsErrors[record.key])
    }
  }, [backendFieldsErrors])

  useEffect(() => {
    /**
     *  Если с бэка пришла ошибка на определенное значение, вызываем редактирование, т.к. только в момент редактирования
     *  сработает form.setFields
     */
    if (fieldErrors.length > 0) {
      toggleEdit()
    }
  }, [fieldErrors])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndexPostfix]: record[dataIndex] })
  }

  const save = async () => {
    try {
      let values
      /** для добавочной ноды нужно валидировать только это поле формы */
      if (isAddedNode) {
        values = await form.validateFields([dataIndexPostfix])
        values[dataIndex] = values[dataIndexPostfix]
        delete values[dataIndexPostfix]
      } else {
        values = await form.validateFields()
      }
      setHasErrors(record?.key, false)
      setFieldErrors([])
      toggleEdit()
      const saveData = [{ ...record, ...values, isEdited: true }]
      handleSave(saveData)
      /** После сохранения добавочной ноды (БИК) нужно снова провалидировать СЧЕТ
       *  Потому что Счет уже может стать нормальным после сохранения Бик
       *  Так же для Счета нужно сохранить данные и очистить ошибки
       */
      if (isAddedNode) {
        values = await form.validateFields()
        setHasErrors(bankAccountName, false)
        setHasErrors(record?.key, false)
        setFieldErrors([])
        saveData.push({ key: bankAccountName, ...values })
        handleSave(saveData)
      }
    } catch (errInfo) {
      setHasErrors(record?.key, true)
      console.log('Save failed:', errInfo)
    }
  }

  const handleChangeField = async () => {
    try {
      await form.validateFields()
      setHasErrors(record?.key, false)
    } catch (errInfo) {
      setHasErrors(record?.key, true)
    }
  }

  let childNode = children

  if (editable && !record?.disableEdit) {
    const validationMessage =
      adminValidationErrors[record.key] || 'Введите, пожалуйста, корректное значение'

    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndexPostfix}
        rules={[
          {
            required: record.required,
            message: `Введите, пожалуйста, ${title}.`
          },
          {
            message: validationMessage,
            validator: (rule, value) =>
              validator({
                rule: { message: validationMessage },
                value,
                name: ['passportAuthority', 'bankName'].includes(record.key) ? 'title' : record.key
              })
          },
          ...(record.specialValidation ? record.specialValidation : []),
          ...(record?.key === profileScreen.input.bankAccount.name
            ? [
                {
                  message: errors.bankBikAccount,
                  validator: (rule, value) =>
                    validateFullBankAccount({
                      rule,
                      account: value,
                      bik: bikValue
                    })
                }
              ]
            : [])
        ]}
      >
        {renderItem || (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} onChange={handleChangeField} />
        )}
      </Form.Item>
    ) : (
      renderItem || (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {record[dataIndex]}
        </div>
      )
    )
  } else if (dataIndex !== 'action') {
    childNode = renderItem ?? record?.[dataIndex] ?? null
  }
  return childNode
}

function EditableTable({
  columns,
  dataSource,
  setDataSource,
  backendFieldsErrors,
  setHasErrors,
  ...restProps
}) {
  const [fieldsErrors, setFieldsErrors] = useState({})

  useEffect(() => {
    /**
     * если хоть с одной ячейки пришла ошибка валидации, ставим запрет на отправку
     */
    setHasErrors(Object.keys(fieldsErrors).filter(key => fieldsErrors[key]))
  }, [fieldsErrors, setHasErrors])

  const handleSave = rowList => {
    const newData = [...dataSource]
    rowList.forEach(row => {
      const index = newData.findIndex(item => row.key === item.key)
      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row
      })
    })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const tableColumns = columns.map(col => {
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        initEditable: record.initEditable,
        required: col.required || true, // по умолчанию поля обязательные
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        backendFieldsErrors,
        setHasErrors: (key, has) => {
          setFieldsErrors({
            ...fieldsErrors,
            [key]: has
          })
        },
        dataSource
      })
    }
  })
  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      dataSource={dataSource}
      columns={tableColumns}
      {...restProps}
    />
  )
}

export default EditableTable
