import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Layout, message, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'

import './SuccessStyle.css'
// import { useDataApi } from '../../../api/useDataApi'
import { useStores } from '../../../Store/Store'
import { PROFILE_CONTRACT_PAGE, PROFILE_MAIN_PAGE } from '../../../routes'
import {
  AppConfig,
  cardStatusCodes,
  employmentTypeStatusCodes,
  fireBriefingStatusCodes,
  innDetailsNameStatusCodes,
  innDetailsStatusCodes,
  lmkStatusCodes,
  partnerStatusCodes
} from '../../../AppConfig'
import { successScreen } from '../../../translates'
// import { getHeaders } from '../../../api/react-query/api'
import ReissueCardRequest from '../../Admin/Partner/EditModals/ReissueCardRequest'
import { useGetPartnerProfile } from '../../../api/react-query/partners'
import MyTaxApp from '../MyTaxApp/MyTaxApp'
import { partnerRoles4MultiOffer } from '../../../Constants'
import InnPhotoDescriptionForm from '../Profile/InnPhotoDescriptionForm'
import ModalChangePhone from '../Profile/ModalChangePhone'

const { Content } = Layout
const { Paragraph } = Typography

const Success = () => {
  const { partnerProfile } = useStores()
  const history = useHistory()
  const profile = partnerProfile.profile
  // const [{ data, isError, isLoading }, doFetch] = useDataApi({})
  const [isSuccessReissueCard, setIsSuccessReissueCard] = useState(false)

  const showShopperMessage = useMemo(() => {
    const DISABLED = true
    return (
      !DISABLED &&
      ![AppConfig.partnerRoles.szContent, AppConfig.partnerRoles.researcher].includes(
        profile?.personalData?.roleCode
      )
    )
  }, [profile?.personalData?.roleCode])

  // useEffect(() => {
  //   if (!data?.isSuccess) return
  //   if (isError) {
  //     return message.error(successScreen.error.message)
  //   }
  //   partnerProfile.setPartnerProfile(data)
  // }, [data, isError])

  // const handleClick = () => {
  //   doFetch('partner/shopper/registered', {
  //     method: 'POST',
  //     headers: getHeaders()
  //   })
  // }

  const {
    isLoading: isLoadingPartner,
    isRefetching: isRefetchingPartner,
    isError: isErrorPartner,
    refetch: refetchPartner
  } = useGetPartnerProfile({
    enabled: false
  })

  useEffect(() => {
    if (isErrorPartner) {
      message.error(successScreen.error.message)
    }
  }, [isErrorPartner])

  const handleOnSuccessReissue = () => {
    setIsSuccessReissueCard(true)
  }

  const renderMessage = () => {
    if (
      (profile?.statusCode === partnerStatusCodes.loginIssued ||
        profile?.statusCode === partnerStatusCodes.blocked) &&
      profile?.payoutSuspended
    ) {
      return (
        <>
          <Paragraph>{successScreen.prefixTitle}</Paragraph>
          <Paragraph>{successScreen.payoutSuspendedTitle}</Paragraph>
        </>
      )
    }

    switch (profile?.statusCode) {
      case partnerStatusCodes.verification:
      case partnerStatusCodes.bddVerification:
        return (
          <>
            {!isShowInnForm && (
              <>
                <Paragraph>{successScreen.prefixTitle}</Paragraph>
                {showShopperMessage && (
                  <Paragraph>
                    <p>{successScreen.correction}</p>
                  </Paragraph>
                )}
              </>
            )}
          </>
        )

      case partnerStatusCodes.loginNotFound:
        return (
          <>
            {showShopperMessage && (
              <Paragraph>
                <p>{successScreen.loginNotFound}</p>
                {profile?.declineReason && (
                  <Alert description={profile?.declineReason} type="error" />
                )}
              </Paragraph>
            )}
            {/* <Spin spinning={isLoading} size='large'> */}
            {/*  <Button */}
            {/*    ghost */}
            {/*    size='large' */}
            {/*    type='primary' */}
            {/*    onClick={handleClick} */}
            {/*    className='margin-top-30' */}
            {/*  > */}
            {/*    {successScreen.button.register} */}
            {/*  </Button> */}
            {/* </Spin> */}
          </>
        )

      case partnerStatusCodes.loginIssued:
        return (
          <>
            <Paragraph>{successScreen.prefixTitle}</Paragraph>
            {showShopperMessage && (
              <Paragraph>
                <p>
                  {successScreen.loginIssued1} {successScreen.shopperLink}{' '}
                  {successScreen.loginIssued2}
                </p>
              </Paragraph>
            )}
          </>
        )

      case partnerStatusCodes.rejected:
        return (
          <>
            <Paragraph>{successScreen.prefixTitle}</Paragraph>
            <Paragraph>{successScreen.title}</Paragraph>
          </>
        )

      // 'Проверен' === partnerStatusCodes.verified
      default:
        return (
          <>
            <Paragraph>{successScreen.prefixTitle}</Paragraph>
            {showShopperMessage && (
              <Paragraph>
                <p>{successScreen.verified}</p>
              </Paragraph>
            )}
          </>
        )
    }
  }

  const goToEditForm = async searchParam => {
    const res = await refetchPartner()
    if (res.isSuccess && res?.data?.partner) {
      partnerProfile.setPartnerProfile(res.data.partner)
      history.push({ pathname: PROFILE_MAIN_PAGE, search: searchParam })
    }
  }

  const handleEditInfo = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editInfo}`)
  }

  const handleEditCard = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editCard}`)
  }

  const handleEditLmk = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editLmk}`)
  }

  const handleEditFireBriefing = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editFireBriefing}`)
  }

  const handleEditEmploymentType = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editEmploymentType}`)
  }

  const isShowInnForm = useMemo(() => {
    return (
      profile?.statusCode === partnerStatusCodes.verification &&
      profile?.innDetails?.nameStatus === innDetailsNameStatusCodes.different &&
      profile?.innDetails?.status === innDetailsStatusCodes.declined &&
      profile?.innDetails?.visible
    )
  }, [profile])

  return (
    <Content className="Success">
      {isShowInnForm && <InnPhotoDescriptionForm profile={profile} />}
      {renderMessage()}
      {[partnerStatusCodes.verification, partnerStatusCodes.verified].includes(
        profile?.statusCode
      ) && <MyTaxApp profile={profile} />}
      {profile?.statusCode === partnerStatusCodes.loginIssued && (
        <>
          <div>
            <Button
              ghost
              size="large"
              type="primary"
              onClick={() => {
                history.push(PROFILE_CONTRACT_PAGE)
              }}
              className="margin-top-30"
            >
              {successScreen.button.myContract}
            </Button>
          </div>
          <div>
            <Button
              ghost
              size="large"
              type="primary"
              className="margin-top-30"
              onClick={handleEditInfo}
              loading={isLoadingPartner || isRefetchingPartner}
            >
              Изменить анкетные данные
            </Button>
          </div>
          <div>
            <ModalChangePhone isButton />
          </div>
          {[cardStatusCodes.canCreate, cardStatusCodes.declined].includes(
            profile?.cardFormStatus
          ) && (
            <div>
              <Button
                ghost
                size="large"
                type="primary"
                className="margin-top-30"
                onClick={handleEditCard}
                loading={isLoadingPartner || isRefetchingPartner}
              >
                Анкета банковской карты
              </Button>
            </div>
          )}
          {[lmkStatusCodes.canCreate, lmkStatusCodes.declined].includes(profile?.lmkFormStatus) && (
            <div>
              <Button
                ghost
                size="large"
                type="primary"
                className="margin-top-30"
                onClick={handleEditLmk}
                loading={isLoadingPartner || isRefetchingPartner}
              >
                Изменить данные ЛМК
              </Button>
            </div>
          )}
          {[fireBriefingStatusCodes.canCreate, fireBriefingStatusCodes.declined].includes(
            profile?.fireBriefingFormStatus
          ) && (
            <div>
              <Button
                ghost
                size="large"
                type="primary"
                className="margin-top-30"
                onClick={handleEditFireBriefing}
                loading={isLoadingPartner || isRefetchingPartner}
              >
                Пожарный инструктаж
              </Button>
            </div>
          )}

          {[
            employmentTypeStatusCodes.none,
            employmentTypeStatusCodes.declined,
            employmentTypeStatusCodes.declinedSmz
          ].includes(profile?.employmentTypeFormStatus) &&
            [...partnerRoles4MultiOffer, AppConfig.partnerRoles.researcher].includes(
              profile?.personalData?.roleCode
            ) && (
              <div>
                <Button
                  ghost
                  size="large"
                  type="primary"
                  className="margin-top-30"
                  onClick={handleEditEmploymentType}
                  loading={isLoadingPartner || isRefetchingPartner}
                >
                  Смена формы занятости
                </Button>
              </div>
            )}

          {profile?.allowCardReissue && !isSuccessReissueCard && (
            <div className="margin-top-30">
              <ReissueCardRequest
                forPartner
                partnerId={partnerProfile?.id}
                onSuccess={handleOnSuccessReissue}
              />
            </div>
          )}
        </>
      )}
    </Content>
  )
}
export default observer(Success)
