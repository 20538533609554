import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { message, Modal, Spin, List } from 'antd'
import {
  useMutateUserSetPriorityPartnerSelector,
  useUsersOnline
} from '../../../api/react-query/users'
import { AppConfig } from '../../../AppConfig'
import { simpleReducer } from '../../../helper'
import {
  useGetVerificationFormStatQuery,
  useGetVerificationStatQuery
} from '../../../api/react-query/stats'
import CoordinatorHeader from './CoordinatorHeader'
import PartnerHeader from './PartnerHeader'
import CoordinatorItem from './CoordinatorItem'
import PartnerItem from './PartnerItem'
import UserCitiesModal from '../UserCities/UserCitiesModal'

const initialState = {
  listData: null,
  isCityModalOpen: false,
  cityModalTitle: '',
  cityModalUserId: null
}

function PartnersStatsList({ isModalOpen = false, onClose = () => {}, statType }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataUsersOnline,
    isSuccess: isSuccessUsersOnline,
    isError: isErrorUsersOnline,
    isLoading: isLoadingUsersOnline
  } = useUsersOnline({
    enabled: isModalOpen && statType === AppConfig.partnerStatTypes.recruitersOnline,
    staleTime: 0,
    cacheTime: 0
  })

  const {
    data: dataVerificationStat,
    isSuccess: isSuccessVerificationStat,
    isError: isErrorVerificationStat,
    isLoading: isLoadingVerificationStat
  } = useGetVerificationStatQuery(statType, {
    enabled:
      isModalOpen &&
      [
        AppConfig.partnerStatTypes.waitingPartners,
        AppConfig.partnerStatTypes.verifiedPartners,
        AppConfig.partnerStatTypes.bddPartners
      ].includes(statType),
    staleTime: 0,
    cacheTime: 0
  })

  const {
    data: dataVerificationFormStat,
    isSuccess: isSuccessVerificationFormStat,
    isError: isErrorVerificationFormStat,
    isLoading: isLoadingVerificationFormStat
  } = useGetVerificationFormStatQuery(statType, {
    enabled:
      isModalOpen &&
      [
        AppConfig.partnerStatTypes.filledProfileForms,
        AppConfig.partnerStatTypes.approvedProfileForms
      ].includes(statType),
    staleTime: 0,
    cacheTime: 0
  })

  const {
    mutate: setPartnerPriority,
    isSuccess: isSuccessSetPriority,
    isError: isErrorSetPriority
  } = useMutateUserSetPriorityPartnerSelector()

  useEffect(() => {
    if (isSuccessUsersOnline && dataUsersOnline?.isSuccess) {
      setState({ listData: dataUsersOnline?.users })
    } else if (isSuccessVerificationStat && dataVerificationStat?.isSuccess) {
      setState({ listData: dataVerificationStat?.items })
    } else if (isSuccessVerificationFormStat && dataVerificationFormStat?.isSuccess) {
      setState({ listData: dataVerificationFormStat?.items })
    }
  }, [
    isSuccessUsersOnline,
    dataUsersOnline,
    isSuccessVerificationStat,
    dataVerificationStat,
    isSuccessVerificationFormStat,
    dataVerificationFormStat
  ])

  useEffect(() => {
    if (isErrorUsersOnline || dataUsersOnline?.errorMessage) {
      message.error(dataUsersOnline?.errorMessage || 'Ошибка получения данных о координаторах')
    } else if (isErrorVerificationStat || dataVerificationStat?.errorMessage) {
      message.error(dataVerificationStat?.errorMessage || 'Ошибка получения данных об Анкетах')
    } else if (isErrorVerificationFormStat || dataVerificationFormStat?.errorMessage) {
      message.error(dataVerificationFormStat?.errorMessage || 'Ошибка получения данных об Анкетах')
    }
  }, [
    isErrorUsersOnline,
    dataUsersOnline,
    isErrorVerificationStat,
    dataVerificationStat,
    isErrorVerificationFormStat,
    dataVerificationFormStat
  ])

  useEffect(() => {
    if (isSuccessSetPriority) {
      message.success('Приоритет анкеты для координатора сохранен')
    } else if (isErrorSetPriority) {
      message.error('Ошибка сохранения данных о приоритете анкеты для координатора')
    }
  }, [isSuccessSetPriority, isErrorSetPriority])

  const title = useMemo(() => {
    switch (true) {
      case statType === AppConfig.partnerStatTypes.bddPartners:
        return 'Партнеры на проверке БДД'
      case statType === AppConfig.partnerStatTypes.waitingPartners:
        return 'Партнеры на проверке'
      case statType === AppConfig.partnerStatTypes.verifiedPartners:
        return 'Партнеры на верификации'
      case statType === AppConfig.partnerStatTypes.recruitersOnline:
        return 'Координаторы на портале'
      case statType === AppConfig.partnerStatTypes.filledProfileForms:
        return 'Корректирующие анкеты для проверки координаторами'
      case statType === AppConfig.partnerStatTypes.approvedProfileForms:
        return 'Корректирующие анкеты для проверки СБ'
      // case statType === AppConfig.partnerStatTypes.newPartners: return 'Новые анкеты'
      // case statType === AppConfig.partnerStatTypes.profileForms: return 'Корректирующие анкеты'
      // case statType === AppConfig.partnerStatTypes.employmentTypeForms: return 'Анкеты о смене занятости'
      // case statType === AppConfig.partnerStatTypes.cardForms: return 'Банковские анкеты'
      // case statType === AppConfig.partnerStatTypes.bankRequisiteForms: return 'Банковские анкеты'
      default:
        return ''
    }
  }, [statType])

  const coordinatorListClass = useMemo(() => {
    return statType === AppConfig.partnerStatTypes.recruitersOnline ? 'coordinator-stats-list' : ''
  }, [statType])

  const messageError = useMemo(() => {
    switch (true) {
      case statType === AppConfig.partnerStatTypes.recruitersOnline:
        return 'Активных координаторов нет'
      default:
        return 'Нет данных об анкетах'
    }
  }, [statType])

  const header = useMemo(() => {
    switch (true) {
      case statType === AppConfig.partnerStatTypes.recruitersOnline:
        return <CoordinatorHeader />
      default:
        return <PartnerHeader statType={statType} />
    }
  }, [statType])

  const renderItem = useCallback(
    item => {
      switch (true) {
        case statType === AppConfig.partnerStatTypes.recruitersOnline:
          return (
            <CoordinatorItem
              item={item}
              cityButton={getCityButton(item)}
              onClickPriority={handlePriorityClick}
            />
          )
        default:
          return <PartnerItem item={item} statType={statType} />
      }
    },
    [statType]
  )

  const handlePriorityClick = (item, selector) => {
    setPartnerPriority({
      userId: item.userId,
      selector
    })
  }

  const handleCityClick = item => {
    setState({
      isCityModalOpen: true,
      cityModalTitle: item.userName,
      cityModalUserId: item.userId
    })
  }

  const getCityButton = item => {
    return <a onClick={() => handleCityClick(item)}>редактировать</a>
  }

  const handleCloseCity = () => {
    setState({
      isCityModalOpen: false,
      cityModalTitle: '',
      cityModalUserId: null
    })
  }

  return (
    <Modal
      className={`partners-stats-list ${coordinatorListClass}`}
      title={title}
      visible={isModalOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Spin
        spinning={
          isLoadingUsersOnline || isLoadingVerificationStat || isLoadingVerificationFormStat
        }
      >
        {state.listData?.length === 0 && <div style={{ textAlign: 'center' }}>{messageError}</div>}
        {Boolean(state.listData?.length) && (
          <List
            className="list"
            bordered
            dataSource={state.listData}
            header={header}
            renderItem={renderItem}
          />
        )}
      </Spin>
      {state.isCityModalOpen && (
        <UserCitiesModal
          visible={state.isCityModalOpen}
          onCancel={handleCloseCity}
          title={`Приоритетные города для выбора анкет - ${state.cityModalTitle}`}
          userId={state.cityModalUserId}
        />
      )}
    </Modal>
  )
}

export default PartnersStatsList
