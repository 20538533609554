import { Button, Modal, message, Input, Row, Col } from 'antd'
import React, { useEffect, useMemo, useReducer } from 'react'

import { useMutateAdminTerminatePartner } from '../../../../api/react-query/adminPartners'
import { useParams } from 'react-router-dom'
import { simpleReducer } from '../../../../helper'

// const { Text } = Typography

const initialState = {
  isShow: false,
  reason: null
}

const ToBan = ({ allowBan = false }) => {
  const { id } = useParams()
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: mutateTerminatePartner,
    data: dataTerminatePartner,
    isError: isErrorTerminatePartner,
    isLoading: isLoadingTerminatePartner,
    isSuccess: isSuccessTerminatePartner
  } = useMutateAdminTerminatePartner()

  useEffect(() => {
    if (isErrorTerminatePartner) {
      message.error(dataTerminatePartner?.errorMessage || 'Ошибка отправки Партнера в БАН.')
    }
  }, [dataTerminatePartner, isErrorTerminatePartner])
  useEffect(() => {
    if (isSuccessTerminatePartner) {
      if (dataTerminatePartner?.errorMessage) {
        return message.error(dataTerminatePartner?.errorMessage)
      }
      setState({ isShow: false })
      message.success('Партнер отправлен в БАН.')
    }
  }, [dataTerminatePartner, isSuccessTerminatePartner])

  const handleOnSave = () => mutateTerminatePartner({ id, reason: state.reason })
  const okButtonProps = useMemo(
    () => ({ className: 'green', loading: isLoadingTerminatePartner }),
    [isLoadingTerminatePartner]
  )
  const cancelButtonProps = useMemo(
    () => ({ disabled: isLoadingTerminatePartner }),
    [isLoadingTerminatePartner]
  )
  if (!allowBan) return null

  return (
    <>
      <Button
        onClick={() => setState({ isShow: true })}
        className="mb-3 mr-3"
        danger
        disabled={isLoadingTerminatePartner}
      >
        Забанить
      </Button>
      <Modal
        width={700}
        visible={state.isShow}
        title="Не рекомендован"
        onCancel={() => setState(initialState)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        {/* <Text>Партнер не сможет оказывать услуги СберЛогистике и повторно зарегистрироваться на Портале</Text> */}
        <Row style={{ marginTop: '1rem' }}>
          <Col span={4}>Причина:</Col>
          <Col span={20}>
            <Input
              value={state.reason}
              onChange={e => {
                setState({ reason: e.target.value })
              }}
              placeholder="Введите причину"
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}
export default ToBan
