import { createContext } from 'react'

export const initConfig = {
  user: {},
  isAdmin: false,
  isCoordinators: false,
  isCoordinator: false,
  isSecurity: false,
  isSupervisor: false,
  isPersonalManager: false,
  isLmkController: false,
  isContentCoordinator: false,
  isResearcherCoordinator: false,
  isCityManager: false,
  isPartnerSupport: false,
  isDivisionalInstructor: false,
  isExternalCoordinator: false,
  isBddOfficer: false,
  partnersStats: null,
  loadingData: {},
  modalData: {},
  portalButtonState: {}
}
export const ConfigContext = createContext({
  ...initConfig,
  setConfig: () => {}
})
