import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminPartnerAcceptEditInfoRequest,
  useMutateAdminPartnerApproveEditInfoRequest,
  useMutateAdminPartnerDeclineEditInfoRequest
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPTED, DELETED, NONE, twoMinutes } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromFormWithFiles,
  flattenObject2,
  genderList,
  getDrivingBlockData,
  // getOnlyInputFieldInPreparedData,
  getPassportIssueDateRules,
  // isEqualRegistrationKindCodes,
  restoreFlattenObject,
  sortByName,
  toLowerFirst,
  toUpperFirst,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed,
  validateFullInn
} from '../../../../helper'
import {
  apiErrorCodes,
  AppConfig,
  individual,
  partnerEditInfoStatusCodes,
  selfEmployed
} from '../../../../AppConfig'
import { ConfigContext } from '../../../../context/configContext'
import { useDrivingCategoriesQuery } from '../../../../api/react-query/dict'
// import RegistrationKindItem from './RegistrationKindItem'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName'
  },
  {
    title: 'Текущее значение',
    dataIndex: 'oldValue',
    width: '35%',
    render: (x, item) =>
      item.key !== profileScreen.input.gender.name ? (
        x
      ) : (
        <div>{genderList.find(g => g.value === x)?.label}</div>
      )
  },
  {
    title: 'Новое значение',
    dataIndex: 'newValue',
    editable: true,
    width: '35%',
    render: (x, item) =>
      item.key !== profileScreen.input.gender.name ? (
        x
      ) : (
        <div>{genderList.find(g => g.value === x)?.label}</div>
      )
  }
]

function EditProfileInfoRequest({
  profile,
  setPartner,
  disabled,
  partnerId,
  viewOnly = false,
  isNoEdit = false
}) {
  const {
    setConfig,
    user,
    portalButtonState,
    isCoordinators,
    isCoordinator,
    isExternalCoordinator
  } = useContext(ConfigContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const isSzContent = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.szContent
  }, [profile?.personalData?.roleCode])

  const isResearcher = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.researcher
  }, [profile?.personalData?.roleCode])

  const documents = useMemo(() => {
    return [
      'passportPhoto1',
      'passportPhoto2',
      'drivingLicencePhoto1',
      'drivingLicencePhoto2',
      // 'passportPhotoExtraReg',
      // 'passportPhotoTmpReg',
      // 'innPhoto',
      'bankDetailsPhoto',
      'egripPhoto',
      'trusteeAgreement',
      'trusteeConfirmation',
      'legalCapacityConfirmation',
      'trusteePassport',
      ...(isSzContent || isResearcher ? [] : ['vaccinacionPhoto'])
    ].filter(d => profile?.form?.[d]?.visible)
  }, [isSzContent, isResearcher, profile?.form])

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminPartnerAcceptEditInfoRequest()
  const {
    mutate: approveRequest,
    data: approveRequestData,
    isLoading: approveRequestLoading,
    isSuccess: approveRequestSuccess,
    isError: approveRequestError
  } = useMutateAdminPartnerApproveEditInfoRequest()
  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminPartnerDeclineEditInfoRequest()

  const { data: dataDrivingCategories, isError: isErrorDrivingCategories } =
    useDrivingCategoriesQuery({
      cacheTime: twoMinutes
    })

  useEffect(() => {
    if (isErrorDrivingCategories || dataDrivingCategories?.message) {
      message.error(dataDrivingCategories?.message || 'Ошибка при загрузке данных категорий ВУ')
    }
  }, [isErrorDrivingCategories, dataDrivingCategories])

  const showModal = () => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalVisible(true)
  }
  const handleCancel = useCallback(() => {
    if (user?.online && (isCoordinators || isCoordinator || isExternalCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalVisible(false)
  }, [isCoordinators, isExternalCoordinator, isCoordinator, portalButtonState, user?.online])

  const prepareAcceptRequestData = useCallback(
    newValues => {
      newValues = restoreFlattenObject(newValues)

      const data = createApiDataFromFormWithFiles({
        personalData: Object.fromEntries(
          Object.entries({
            firstName: newValues.firstName,
            middleName:
              profile?.form?.personalDetails?.withoutMiddleName && !newValues.middleName
                ? ''
                : newValues.middleName,
            surname: newValues.surname,
            withoutMiddleName:
              newValues.middleName !== undefined ? !newValues.middleName : undefined,
            gender: newValues.gender,
            email: newValues.email,
            city: newValues.city,
            passport: newValues.passport,
            registrationAddress: Object.fromEntries(
              Object.entries({
                ...newValues.registrationAddress
              }).map(([key, value]) => (value === null ? [key, ''] : [key, value]))
            ),
            drivingLicence: Object.fromEntries(
              Object.entries({
                ...newValues.drivingLicence
              }).map(([key, value]) => (value === null ? [key, ''] : [key, value]))
            ),
            registrationKind: profile?.form?.personalDetails?.registrationKindCode,
            isLegalAgeCapable: profile?.form?.personalDetails?.isLegalAgeCapable
          }).filter(([, value]) => value !== undefined)
        ),
        bankDetails: Object.fromEntries(
          Object.entries({
            bankName: newValues.bankName,
            name: newValues.name,
            bankAccount: newValues.bankAccount,
            bankBik: newValues.bankBik
          }).filter(([, value]) => value !== undefined)
        ),
        bankDetailsPhoto: newValues.bankDetailsPhoto,
        passportPhoto1: newValues.passportPhoto1,
        passportPhoto2: newValues.passportPhoto2,
        passportPhotoExtraReg: newValues.passportPhotoExtraReg,
        passportPhotoTmpReg: newValues.passportPhotoTmpReg,
        egripPhoto: newValues.egripPhoto,
        trusteeAgreement: newValues.trusteeAgreement,
        trusteeConfirmation: newValues.trusteeConfirmation,
        legalCapacityConfirmation: newValues.legalCapacityConfirmation,
        trusteePassport: newValues.trusteePassport,
        drivingLicencePhoto1: newValues.drivingLicencePhoto1,
        drivingLicencePhoto2: newValues.drivingLicencePhoto2
        // innPhoto: newValues.innPhoto,
        // vaccinacionPhoto: newValues.vaccinacionPhoto,
        // innDetails: { inn: newValues.inn }
      })

      delete data?.personalData?.birthDate

      return {
        ...(Object.keys(data.personalData).length && { personalDetails: data.personalData }),
        ...(Object.keys(data.bankDetails).length && { bankDetails: data.bankDetails }),
        // ...(Object.keys(data.innDetails).length && { innDetails: data.innDetails }),
        bankDetailsPhoto: newValues.bankDetailsPhoto,
        passportPhoto1: newValues.passportPhoto1,
        passportPhoto2: newValues.passportPhoto2,
        passportPhotoExtraReg: newValues.passportPhotoExtraReg,
        passportPhotoTmpReg: newValues.passportPhotoTmpReg,
        egripPhoto: newValues.egripPhoto,
        trusteeAgreement: newValues.trusteeAgreement,
        trusteeConfirmation: newValues.trusteeConfirmation,
        legalCapacityConfirmation: newValues.legalCapacityConfirmation,
        trusteePassport: newValues.trusteePassport,
        drivingLicencePhoto1: newValues.drivingLicencePhoto1,
        drivingLicencePhoto2: newValues.drivingLicencePhoto2
        // innPhoto: newValues.innPhoto,
        // vaccinacionPhoto: newValues.vaccinacionPhoto
      }
    },
    [profile]
  )

  const handleCommit = ({ outcome, comment: reason }) => {
    setBackendFieldsErrors({})
    if (outcome === ACCEPT) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)

      const data = prepareAcceptRequestData({
        ...newValues,
        bankDetailsPhoto: profile?.form?.bankDetailsPhoto?.fileId,
        passportPhoto1: profile?.form?.passportPhoto1?.fileId,
        passportPhoto2: profile?.form?.passportPhoto2?.fileId,
        passportPhotoExtraReg: profile?.form?.passportPhotoExtraReg?.fileId,
        passportPhotoTmpReg: profile?.form?.passportPhotoTmpReg?.fileId,
        // innPhoto: profile?.form?.innPhoto?.fileId,
        egripPhoto: profile?.form?.egripPhoto?.fileId,
        trusteeAgreement: profile?.form?.trusteeAgreement?.fileId,
        trusteeConfirmation: profile?.form?.trusteeConfirmation?.fileId,
        legalCapacityConfirmation: profile?.form?.legalCapacityConfirmation?.fileId,
        drivingLicencePhoto1: profile?.form?.drivingLicencePhoto1?.fileId,
        drivingLicencePhoto2: profile?.form?.drivingLicencePhoto2?.fileId,
        trusteePassport: profile?.form?.trusteePassport?.fileId
        // vaccinacionPhoto: profile?.form?.vaccinacionPhoto?.fileId
      })

      const driveCatsName = profileScreen.input.drivingLicenceAllowedCategories.name
      if (Object.keys(profile?.form?.personalDetails?.drivingLicence || {}).length) {
        data.personalDetails.drivingLicence[driveCatsName] =
          profile?.form?.personalDetails?.drivingLicence[driveCatsName]
      }

      if (profile?.form?.statusCode === partnerEditInfoStatusCodes.filled) {
        approveRequest({ id: partnerId, data })
      } else {
        acceptRequest({ id: partnerId, data })
      }
    } else {
      declineRequest({ id: partnerId, reason })
    }
  }

  useEffect(() => {
    const data = []
    const flattenPersonalDataForm = flattenObject2({
      ...(profile?.form?.personalDetails || {}),
      ...getDrivingBlockData(
        profile?.form?.personalDetails?.drivingLicence,
        sortByName(dataDrivingCategories?.result || [])
      )
      // [profileScreen.input.inn.name]: profile?.form?.innDetails?.inn
    })
    const flattenPersonalDataProfile = flattenObject2({
      ...(profile?.personalData || {}),
      ...getDrivingBlockData(
        profile?.personalData?.drivingLicence,
        sortByName(dataDrivingCategories?.result || [])
      )
      // [profileScreen.input.inn.name]: profile?.innDetails?.inn
    })

    // не показываем поле "пол" если оно было указано в профиле и в кор анкете не менялось
    if (!profile?.form?.personalDetails?.gender) {
      delete flattenPersonalDataForm.gender
    }

    const notRequiredKeys = [
      profileScreen.input.middleName.name,
      `registrationAddress.${profileScreen.input.registrationAddressDistrict.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressCity.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressSettlement.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressBuilding.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressApartment.name}`
    ]

    Object.keys(flattenPersonalDataForm)?.map(key => {
      if (
        [
          profileScreen.input.withoutMiddleName.name,
          `${profileScreen.input.registrationKind.name}Code`,
          profileScreen.input.isLegalAgeCapable.name
        ].includes(key)
      ) {
        return
      }
      data.push({
        key,
        required: !notRequiredKeys.includes(key),
        disableEdit:
          key === `drivingLicence.${profileScreen.input.drivingLicenceAllowedCategories.name}`,
        // переводим дот нотацию в аперкейс: "registrationAddress.district" -> "registrationAddressDistrict"
        fieldName:
          profileScreen.input[
            toLowerFirst(key?.split('.')?.reduce((o, i) => o + toUpperFirst(i), ''))
          ]?.label,
        oldValue: flattenPersonalDataProfile?.[key],
        newValue: flattenPersonalDataForm?.[key],
        specialValidation: [
          ...(key === profileScreen.input.inn.name
            ? [
                {
                  message: errors.innCheckSum,
                  validator: (rule, value) => validateFullInn({ rule, inn: value })
                }
              ]
            : []),
          ...([
            `passport.${profileScreen.input.passportIssueDate.name}`,
            `drivingLicence.${profileScreen.input.drivingLicenceIssueDate.name}`
          ].includes(key)
            ? getPassportIssueDateRules()
            : [])
        ]
      })
    })
    Object.keys(profile?.form?.bankDetails || {})?.map(key => {
      if (key === profileScreen.input.bankDetailsPhoto.name) {
        return
      }
      data.push({
        key,
        fieldName: profileScreen.input[key]?.label,
        oldValue: profile?.bankDetails?.[key],
        newValue: profile?.form?.bankDetails?.[key],
        specialValidation: [
          ...(key === profileScreen.input.bankAccount.name &&
          profile?.personalData?.legalForm === individual
            ? [
                {
                  message: errors.bankAccount,
                  validator: validateBankAccountIndividual
                }
              ]
            : []),
          ...(key === profileScreen.input.bankAccount.name &&
          profile?.personalData?.legalForm === selfEmployed
            ? [
                {
                  message: errors.bankAccountSelf,
                  validator: validateBankAccountSelfEmployed
                }
              ]
            : [])
        ]
      })
    })

    const orderKeys = [
      profileScreen.input.surname.name,
      profileScreen.input.firstName.name,
      profileScreen.input.middleName.name,
      profileScreen.input.gender.name,
      `passport.${profileScreen.input.passportNumber.name}`,
      `passport.${profileScreen.input.passportSeries.name}`,
      `passport.${profileScreen.input.passportIssuerName.name}`,
      `passport.${profileScreen.input.passportIssuerCode.name}`,
      `passport.${profileScreen.input.passportIssueDate.name}`,
      profileScreen.input.email.name,
      profileScreen.input.city.name,
      `drivingLicence.${profileScreen.input.drivingLicenceSeriesNumber.name}`,
      `drivingLicence.${profileScreen.input.drivingLicenceAllowedCategories.name}`,
      `drivingLicence.${profileScreen.input.drivingLicenceIssuerName.name}`,
      `drivingLicence.${profileScreen.input.drivingLicenceIssueDate.name}`,
      profileScreen.input.bankName.name,
      profileScreen.input.bankAccount.name,
      profileScreen.input.bankBik.name,
      `registrationAddress.${profileScreen.input.registrationAddressCountry.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressZipCode.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressRegion.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressDistrict.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressCity.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressSettlement.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressStreet.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressHouse.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressBuilding.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressApartment.name}`
    ]
    setProfileData(arraySortByArray(data, orderKeys, 'key'))
  }, [profile])

  useEffect(() => {
    if (acceptRequestError || approveRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError, approveRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        if (acceptRequestData.errorCode === apiErrorCodes.innDuplicate) {
          setBackendFieldsErrors({ inn: [acceptRequestData.errorMessage] })
        }
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
      setConfig({
        modalData: { isEditProfileInfoRequest: isModalVisible, refetchProfileInfo: true }
      })
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (approveRequestSuccess) {
      if (approveRequestData?.errorMessage) {
        if (approveRequestData.errorCode === apiErrorCodes.innDuplicate) {
          setBackendFieldsErrors({ inn: [approveRequestData.errorMessage] })
        }
        return message.error(approveRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
      setConfig({
        modalData: { isEditProfileInfoRequest: isModalVisible, refetchProfileInfo: true }
      })
    }
  }, [approveRequestData, approveRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  useEffect(() => {
    setConfig({ modalData: { isEditProfileInfoRequest: isModalVisible } })
  }, [isModalVisible])

  const getRowClassName = record =>
    !(!record.oldValue && !record.newValue) && record.newValue !== record.oldValue
      ? 'editable-row bg-changed'
      : 'editable-row'

  // const getAcceptButton = useCallback((requestData, fieldName, profileData) => (
  //   <Button
  //     size='small' type='primary' onClick={() => {
  //       // Счет и БИК данные отправляются на бэк сразу вместе. Подхватим данные БИК из общего объекта профиля
  //       const accountName = profileScreen.input.bankAccount.name
  //       const bikName = profileScreen.input.bankBik.name
  //       const passportPhotoTmpReg = profileScreen.input.passportPhotoTmpReg.name
  //       const trusteeConfirmation = profileScreen.input.trusteeConfirmation.name
  //       const legalCapacityConfirmation = profileScreen.input.legalCapacityConfirmation.name
  //       let data = getOnlyInputFieldInPreparedData(requestData, prepareAcceptRequestData(requestData))
  //       data = {
  //         ...data,
  //         ...(fieldName === accountName && profileData && {
  //           bankDetails: {
  //             ...data.bankDetails,
  //             [bikName]: profileData.filter(item => item.key === bikName)[0].newValue
  //           }
  //         }),
  //         ...(fieldName === passportPhotoTmpReg && profileData && {
  //           personalDetails: {
  //             ...data.personalDetails,
  //             registrationKind: profileData.personalDetails?.registrationKindCode
  //           }
  //         }),
  //         ...([trusteeConfirmation, legalCapacityConfirmation].includes(fieldName) && profileData && {
  //           personalDetails: {
  //             ...data.personalDetails,
  //             isLegalAgeCapable: profileData.personalDetails?.isLegalAgeCapable
  //           }
  //         })
  //       }
  //       if (profile?.form?.statusCode === partnerEditInfoStatusCodes.filled) {
  //         approveRequest({ id: partnerId, data })
  //       } else {
  //         acceptRequest({ id: partnerId, data })
  //       }
  //     }}
  //     disabled={fieldsHasErrors?.includes(fieldName)}
  //   >
  //     Принять
  //   </Button>
  // ), [acceptRequest, approveRequest, prepareAcceptRequestData, fieldsHasErrors, partnerId, profile])

  const tableProps = useMemo(
    () => ({
      backendFieldsErrors,
      setHasErrors: setFieldsHasErrors,
      columns: columns,
      // columns: [...columns,
      //   ...!viewOnly ? [{
      //     title: '',
      //     dataIndex: 'action',
      //     width: 105,
      //     render: (value, record) => {
      //       return (!(!record.oldValue && !record.newValue) && record.oldValue !== record.newValue) || record?.isEdited
      //         ? getAcceptButton(
      //           restoreFlattenObject({ [record.key]: record.newValue }),
      //           record.key,
      //           profileData
      //         )
      //         : <>&nbsp;</>
      //     }
      //   }] : []],
      dataSource: profileData,
      setDataSource: setProfileData,
      pagination: false,
      rowClassName: getRowClassName
    }),
    [backendFieldsErrors, profileData, viewOnly]
  )

  const profilePhotos = useMemo(() => {
    const list = documents.reduce((photos, doc) => {
      if (profile?.[doc]?.visible) {
        return {
          ...photos,
          ...(profile?.form?.[doc]?.fileId
            ? { [doc]: profile?.form?.[doc] }
            : { [doc]: profile?.[doc] })
        }
      }
      return photos
    }, {})
    return list
  }, [profile, documents])

  const getDocumentStatus = useCallback(
    doc => {
      return profile?.[doc]?.fileId && !profile?.form?.[doc]?.fileId
        ? DELETED
        : profile?.[doc]?.fileId === profile?.form?.[doc]?.fileId
          ? ACCEPTED
          : NONE
    },
    [profile]
  )

  const getDocumentButton = useCallback(
    doc => {
      return viewOnly || profile?.[doc]?.fileId === profile?.form?.[doc]?.fileId ? null : profile?.[
          doc
        ]?.fileId && !profile?.form?.[doc]?.fileId ? (
        <Text type="danger">Удален</Text>
      ) : (
        <>&nbsp;</>
      )
      // : getAcceptButton(
      //   { [doc]: profile?.form?.[doc]?.fileId },
      //   doc,
      //   profile?.form
      // )
    },
    [viewOnly, profile]
  )

  // const registrationKindButton = useMemo(() => {
  //   return viewOnly || isEqualRegistrationKindCodes(profile?.personalData?.registrationKindCode, profile?.form?.personalDetails?.registrationKindCode)
  //     ? null
  //     : getAcceptButton(
  //       { personalData: { registrationKind: profile?.form?.personalDetails?.registrationKindCode } },
  //       profileScreen.input.passportPhotoTmpReg.name,
  //       profile?.form
  //     )
  // }, [viewOnly, profile, getAcceptButton])

  const isLoadingForm = useMemo(() => {
    return !profile?.form || acceptRequestLoading || declineRequestLoading || approveRequestLoading
  }, [profile?.form, acceptRequestLoading, declineRequestLoading, approveRequestLoading])

  return (
    <div>
      <Button ghost type="primary" className="w-150" disabled={disabled} onClick={showModal}>
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align="middle">
            <Col className="mr-3">
              <b>Корректировка анкеты партнера</b>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="assetsModalForm"
      >
        <Row className="mb-3">
          <Text underline>
            Тип занятости: <b>{profile?.personalData?.legalForm}</b>
          </Text>
        </Row>
        <Spin spinning={isLoadingForm}>
          {/* {profile?.registrationFlow && !(isSzContent || isResearcher) && ( */}
          {/*  <RegistrationKindItem */}
          {/*    form={profile?.form} */}
          {/*    acceptButton={registrationKindButton} */}
          {/*  /> */}
          {/* )} */}
          {profile?.form?.statusReason && (
            <Alert
              className="mb-3"
              message={<Text type="secondary">Причины отказа</Text>}
              description={profile?.form?.statusReason}
              type="error"
            />
          )}
          {viewOnly || isNoEdit ? <Table {...tableProps} /> : <EditableTable {...tableProps} />}
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={{ ...profile?.form, ...profile?.form?.bankDetails, ...profilePhotos }}
            getDocumentStatus={getDocumentStatus}
            getDocumentButton={getDocumentButton}
            correctForm={AppConfig.updateDocFormNames.form}
          />
          <Divider />
          {viewOnly ? (
            <Button type="primary" onClick={handleCancel}>
              Закрыть
            </Button>
          ) : (
            <Approval
              onCommit={handleCommit}
              onCancel={handleCancel}
              multiline
              multilineRows={2}
              disabled={fieldsHasErrors?.length}
              autocomplete
              targetType={AppConfig.reasonTargets.partnerForm}
            />
          )}
        </Spin>
      </Modal>
    </div>
  )
}

export default EditProfileInfoRequest
