import { Form, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { profileScreen } from '../../../../translates'
import './Driving.css'
import { useDrivingCategoriesQuery } from '../../../../api/react-query/dict'
import { twoMinutes } from '../../../../Constants'
import { sortByName } from '../../../../helper'

const DrivingCategoriesFormItem = ({
  label = profileScreen.input.drivingLicenceAllowedCategories.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.drivingLicenceAllowedCategories.message,
  size = 'large',
  placeholder = profileScreen.input.drivingLicenceAllowedCategories.placeholder,
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  const [state, setState] = useState({
    propList: []
  })

  const {
    data: dataDrivingCategories,
    loading: isLoadingDrivingCategories,
    isSuccess: isSuccessDrivingCategories,
    isError: isErrorDrivingCategories
  } = useDrivingCategoriesQuery({
    cacheTime: twoMinutes
  })

  useEffect(() => {
    if (isSuccessDrivingCategories && dataDrivingCategories?.isSuccess) {
      setState({
        propList:
          sortByName(dataDrivingCategories?.result || [])?.map(({ id, name }) => ({
            label: name,
            value: id
          })) ?? []
      })
    } else if (isErrorDrivingCategories || dataDrivingCategories?.message) {
      message.error(dataDrivingCategories?.message || 'Ошибка при загрузке данных категорий ВУ')
    }
  }, [isSuccessDrivingCategories, isErrorDrivingCategories, dataDrivingCategories])

  return (
    <Form.Item
      name={[
        'personalData',
        'drivingLicence',
        profileScreen.input.drivingLicenceAllowedCategories.name
      ]}
      label={label}
      rules={
        rules ?? [
          {
            required: requiredRule,
            message: messageRule,
            ...(validator ? { validator } : {})
          }
        ]
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        size={size}
        placeholder={placeholder}
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
        loading={isLoadingDrivingCategories}
        options={state?.propList}
      />
    </Form.Item>
  )
}
export default DrivingCategoriesFormItem
