export const selfEmployed = 'Самозанятый'
export const individual = 'ИП'
export const apiErrorCodes = {
  innDuplicate: 'InnDuplicate',
  other: 'Generic',
  partnerUuidNotFound: 'PartnerUuidNotFound',
  partnerTaken: 'partnerTaken'
}
export const partnerStatusCodes = {
  new: 'new',
  draft: 'draft',
  profileFilled: 'profileFilled',
  verification: 'verification',
  correction: 'correction',
  verified: 'verified',
  loginIssued: 'loginIssued',
  loginNotFound: 'loginNotFound',
  rejected: 'rejected',
  blocked: 'blocked',
  free: 'free',
  archive: 'archive',
  bddVerification: 'bddVerification'
}
export const cardStatusCodes = {
  canCreate: 'canCreate',
  filling: 'filling',
  verification: 'verification',
  accepted: 'accepted',
  declined: 'declined',
  canCreateDeclined: 'canCreateDeclined',
  none: 'none'
}
export const lmkStatusCodes = {
  canCreate: 'canCreate',
  filling: 'filling',
  verification: 'verification',
  accepted: 'accepted',
  declined: 'declined',
  none: 'none'
}
export const requisitesStatusCodes = {
  canCreate: 'canCreate',
  filling: 'filling',
  verification: 'verification',
  accepted: 'accepted',
  declined: 'declined',
  none: 'none'
}
export const fireBriefingStatusCodes = {
  canCreate: 'canCreate',
  filling: 'filling',
  verification: 'verification',
  accepted: 'accepted',
  declined: 'declined',
  none: 'none'
}
export const employmentTypeStatusCodes = {
  // canCreate: 'canCreate',
  // filling: 'filling',
  verification: 'verification',
  selfieCheck: 'selfieCheck',
  accepted: 'accepted',
  declined: 'declined',
  declinedSmz: 'declinedSmz',
  none: 'none'
}
export const seVerificationStatusCodes = {
  allowed: 'allowed',
  denied: 'denied',
  manualCheck: 'manualCheck'
}
const cardIssueStatusCodes = {
  error: 'error'
}
export const partnerEditInfoStatusCodes = {
  new: 'new',
  filled: 'filled',
  accepted: 'accepted',
  approved: 'approved',
  declined: 'declined',
  canceled: 'canceled',
  none: 'none'
}
export const partnerFormStatusCodes = {
  canCreate: 'canCreate',
  verification: 'verification',
  declined: 'declined'
}
export const innDetailsStatusCodes = {
  none: 'none',
  declined: 'declined',
  accepted: 'accepted'
}
export const innDetailsNameStatusCodes = {
  waiting: 'waiting',
  different: 'different'
}

export const partnerStatusesByKey = {
  [partnerStatusCodes.new]: 'Новый',
  [partnerStatusCodes.draft]: 'Черновик',
  [partnerStatusCodes.profileFilled]: 'Профиль заполнен',
  [partnerStatusCodes.verification]: 'Ожидает проверки',
  [partnerStatusCodes.correction]: 'Требуются изменения',
  [partnerStatusCodes.verified]: 'Проверен',
  [partnerStatusCodes.loginIssued]: 'Учетная запись верифицирована',
  [partnerStatusCodes.loginNotFound]: 'Учетная запись не найдена',
  [partnerStatusCodes.rejected]: 'Отклонен',
  [partnerStatusCodes.blocked]: 'Заблокирован',
  [partnerStatusCodes.free]: 'Свободный партнер'
}

export const partnerSecurityStatusCodes = {
  none: 'none',
  approved: 'approved',
  rejected: 'rejected'
}
export const partnerSecurityStatusesByKey = {
  [partnerSecurityStatusCodes.none]: 'Не проверен',
  [partnerSecurityStatusCodes.approved]: 'Принят СБ',
  [partnerSecurityStatusCodes.rejected]: 'Отказ СБ'
}

export const partnerKinds = {
  none: 'none',
  new: 'new',
  old: 'old'
}
export const partnerKindsByKey = {
  [partnerKinds.none]: 'Не задано',
  [partnerKinds.new]: 'Новый партнер',
  [partnerKinds.old]: 'Старый партнер'
}

export const partnerYoudoStatusCodes = {
  none: 'none',
  notApplicable: 'notApplicable',
  created: 'created',
  notSelfEmployed: 'notSelfEmployed',
  unboundToTalkBank: 'unboundToTalkBank',
  boundToTalkBank: 'boundToTalkBank'
}
export const partnerYoudoStatusesByKey = {
  [partnerYoudoStatusCodes.none]: 'Нет статуса',
  [partnerYoudoStatusCodes.notApplicable]: 'Не применимо',
  [partnerYoudoStatusCodes.created]: 'Создан',
  [partnerYoudoStatusCodes.notSelfEmployed]: 'Не оформлен',
  [partnerYoudoStatusCodes.unboundToTalkBank]: 'Не проверен',
  [partnerYoudoStatusCodes.boundToTalkBank]: 'Можно платить'
}

const groups = {
  administrators: 'administrators',
  security: 'security',
  operations: 'operations',
  coordinator: 'coordinator',
  techsupport: 'techsupport',
  // deprecated
  supervisors: 'supervisors',
  citymanagers: 'citymanagers',
  coordinators: 'coordinators',
  user: 'user',
  divisionalinstructors: 'divisionalinstructors',
  personalmanagers: 'personalmanagers',
  lmkcontrollers: 'lmkcontrollers',
  contentcoordinators: 'contentcoordinators',
  researchercoordinators: 'researchcoordinators',
  externalcoordinators: 'externalcoordinators',
  partnersupport: 'partnersupport',
  bddofficer: 'bddofficer'
}

const reportStatuses = {
  ready: 'ready',
  queued: 'queued'
}

const reportTypes = {
  assets: 'assets',
  partners: 'partners',
  partnersVerification: 'partnersVerification',
  partnersChanges: 'partnersChanges',
  passportDetails: 'passportDetails',
  bankDetails: 'bankDetails',
  partnersLmk: 'partnersLmk',
  partnersSzContent: 'partnersSzContent',
  partnersResearcher: 'partnersSzResearcher',
  declinedCardForms: 'declinedCardForms',
  fireBriefing: 'fireBriefing',
  virtualCards: 'virtualCards',
  coordinatorsTime: 'coordinatorsTime',
  bankRequisite: 'bankRequisite',
  profileForm: 'profileForm'
}

const reportTypeLabels = {
  [reportTypes.assets]: 'имущество',
  [reportTypes.partners]: 'партнеры',
  [reportTypes.partnersChanges]: 'история данных',
  [reportTypes.passportDetails]: 'паспортные данные',
  [reportTypes.bankDetails]: 'банковские данные'
}

const uploadFileStatuses = {
  error: 'error',
  success: 'success',
  done: 'done',
  uploading: 'uploading',
  removed: 'removed'
}

const documentNameList = [
  'passportPhoto1',
  'passportPhoto2',
  'passportPhotoExtraReg',
  'passportPhotoTmpReg',
  'passportPhoto3',
  'innPhoto',
  'mnregPhoto',
  'egripPhoto',
  'bankDetailsPhoto',
  'trusteeAgreement',
  'trusteePassport',
  'trusteeConfirmation',
  'legalCapacityConfirmation',
  'vaccinacionPhoto',
  'lmkMainPhoto',
  'lmkAllowancePhoto',
  'lmkAttestationPhoto',
  'snilsPhoto',
  'drivingLicencePhoto1',
  'drivingLicencePhoto2'
]

const updateDocFormNames = {
  form: 'profile/form',
  cardForm: 'card/form',
  bankRequisitesForm: 'bankRequisites/form',
  lmkForm: 'lmk/form',
  employmentTypeForm: 'employmentType/form'
}

const reasonTargets = {
  personalData: 'personalData',
  bankDetails: 'bankDetails',
  innDetails: 'innDetails',
  requisitesDetails: 'requisitesDetails',
  cardForm: 'cardForm',
  partnerForm: 'partnerForm',
  fireBriefingForm: 'fireBriefingForm',
  verification: 'verification',
  employmentTypeForm: 'employmentTypeForm',
  seVerification: 'seVerification'
}

const reasonTargetLabels = {
  [reasonTargets.personalData]: 'Анкетные данные',
  [reasonTargets.bankDetails]: 'Банковские реквизиты',
  [reasonTargets.innDetails]: 'ИНН',
  [reasonTargets.requisitesDetails]: 'Анкета банковских реквизитов',
  [reasonTargets.cardForm]: 'Банковская анкета',
  [reasonTargets.partnerForm]: 'Корректировка анкеты',
  [reasonTargets.fireBriefingForm]: 'Пожарный инструктаж',
  [reasonTargets.verification]: 'Ошибка верификации',
  [reasonTargets.employmentTypeForm]: 'Смена занятости',
  [reasonTargets.seVerification]: 'Проверка в 1С'
}

const uploadTypes = {
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf'
}

const assetActTypes = {
  return: 'return',
  transfer: 'transfer',
  writeOff: 'writeOff'
}

const inventoryTypes = {
  new: 'new',
  used: 'used',
  disposal: 'disposal'
}

const inventoryTypeLabels = {
  [inventoryTypes.new]: 'Новый',
  [inventoryTypes.used]: 'Б/У',
  [inventoryTypes.disposal]: 'Утилизация'
}

const partnerSteps = {
  stepProfile: 0,
  stepOffer: 1,
  stepSuccess: 2
}

const partnerRoles = {
  szContent: 'szContent',
  researcher: 'szResearcher',
  packer: 'packer',
  courier: 'courier',
  footCourier: 'footCourier',
  automotiveCourier: 'automotiveCourier',
  packerAndFootCourier: 'packerAndFootCourier',
  packerAndAutomotiveCourier: 'packerAndAutomotiveCourier',
  bikeCourier: 'bikeCourier',
  sberLogisticPartner: 'sberLogisticPartner'
}

const countries = {
  Russia: 'Россия',
  Armenia: 'Армения',
  Belarus: 'Белоруссия',
  Kazakhstan: 'Казахстан',
  Kirghizia: 'Киргизия'
}

const smzStatusCodes = {
  bindingSending: 'bindingSending'
}

const smzErrorCodes = {
  fnsError: 'fns_error',
  netError: 'net_error',
  invalidInn: 'invalid_inn',
  appError: 'app_error',
  partnerUnregistered: 'partner_unregistered',
  unregistered: 'unregistered',
  requestNotProcessed: 'request_not_processed'
}

const smzRequiredActions = {
  ConfirmSmz: 'ConfirmSmz',
  ReaffirmBind: 'ReaffirmBind',
  ConfirmBind: 'ConfirmBind',
  SendCreate: 'SendCreate',
  SendBind: 'SendBind'
}

const shopperStatuses = {
  verified: 'verified',
  notfound: 'notfound'
}

const bddStatuses = {
  none: 'none',
  verification: 'verification',
  approved: 'approved',
  rejected: 'rejected'
}

const bddStatusesAct = {
  [bddStatuses.approved]: 'approve',
  [bddStatuses.rejected]: 'reject'
}

const legalFormCodes = {
  individual: 'individual',
  self: 'self'
}

const legalFormLabels = {
  [legalFormCodes.individual]: individual,
  [legalFormCodes.self]: selfEmployed
}

const legalFormLabelCodes = {
  [individual]: legalFormCodes.individual,
  [selfEmployed]: legalFormCodes.self
}

const registrationTypes = {
  permanent: 'permanent',
  unregistered: 'unregistered',
  temporary: 'temporary'
}
const registrationTypeLabels = {
  [registrationTypes.unregistered]: 'Нет регистрации РФ',
  [registrationTypes.temporary]: 'Временная регистрация РФ',
  [registrationTypes.permanent]: 'Постоянная регистрация РФ'
}

const phoneEditErrorCodes = {
  ForceChangeRequired: 'ForceChangeRequired',
  PhoneDuplicate: 'PhoneDuplicate',
  InvalidMobilePhoneForThisOperation: 'InvalidMobilePhoneForThisOperation',
  PartnerSmsBanned: 'PartnerSmsBanned'
}

const partnerStatTypes = {
  waitingPartners: 'waitingPartners',
  verifiedPartners: 'verifiedPartners',
  recruitersOnline: 'recruitersOnline',
  filledProfileForms: 'filledProfileForms',
  approvedProfileForms: 'approvedProfileForms',
  bddPartners: 'bddPartners'

  // coordinatorsOnline: 'coordinatorsOnline',
  // cardForms: 'cardForms',
  // bankRequisiteForms: 'bankRequisiteForms',
  // profileForms: 'profileForms',
  // newPartners: 'newPartners',
  // employmentTypeForms: 'employmentTypeForms'
}

const urlRoleCodes = {
  selfEmployed: 'selfEmployed',
  selfEmployedResearcher: 'selfEmployedResearcher'
}

const formScreenActions = {
  editInfo: 'editInfo',
  editCard: 'editCard',
  editLmk: 'editLmk',
  editFireBriefing: 'editFireBriefing',
  editEmploymentType: 'editEmploymentType',
  offerType: 'offerType',
  offer: 'offer',
  photoDoc: 'photoDoc'
}

const sberId = {
  authSberId: 'authSberId',
  buttonType: {
    auth: 'auth',
    profile: 'profile'
  },
  oidcParams: {
    response_type: 'response_type', // 'code',
    client_type: 'client_type', // 'PRIVATE',
    client_id: 'client_id', // 'a1b8a9a9-0ac6-46bc-a565-45d876a33b9e',
    redirect_uri: 'redirect_uri', // 'https://dev.instamartoffers.improvity.ru/partners/oidc',
    state: 'state', // 'ut8Ar4MUZEMDPIiD2ko914y37s0Q0VKJgxeCkU0yzTY',
    scope: 'scope', // 'openid name',
    nonce: 'nonce', // 'NfZscgwxPY7v0kYvuPfnFHA57bqHxQc3lV51Oiaxlo4',
    login_hint: 'login_hint', // '79150058523',
    anyParam: 'anyParam' // 'param'
  }
}

const tinkoffId = {
  authTinkoffId: 'authTinkoffId',
  buttonType: {
    auth: 'auth',
    profile: 'profile'
  },
  buttonLabel: {
    auth: 'Войти с Tinkoff',
    profile: 'Tinkoff'
  },
  widgetSrc: 'https://sso-forms-prod.cdn-tinkoff.ru/tinkoff-id/widget.js',
  bankDetailsSource: 'Tinkoff'
}

const coordinatorTakePriority = {
  new: 'new',
  old: 'old',
  bankRequisite: 'bankRequisite'
}

export const DISABLE_VACCINATED = true

export const AppConfig = {
  formats: {
    shortDate: 'DD.MM.YYYY',
    shortTime: 'HH:mm',
    shortDateAndTime: 'DD.MM.YYYY HH:mm',
    shortDateApi: 'YYYY-MM-DD',
    shortDateAndTimeApi: 'YYYY-MM-DDTHH:mm',
    excelShortDate: 'dd.mm.yyyy;@',
    excelShortTime: 'HH:mm;@'
  },
  countries,
  legalForms: [individual, selfEmployed],
  legalFormCodes,
  legalFormLabels,
  legalFormLabelCodes,
  partnerSteps,
  partnerStatus: [
    partnerStatusesByKey.new,
    partnerStatusesByKey.draft,
    partnerStatusesByKey.profileFilled,
    partnerStatusesByKey.verification,
    partnerStatusesByKey.correction,
    partnerStatusesByKey.verified,
    partnerStatusesByKey.loginIssued,
    partnerStatusesByKey.loginNotFound
  ],
  groups,
  disabledVisibilityScopeGroups: [
    groups.administrators,
    groups.coordinators,
    groups.coordinator,
    groups.security,
    groups.divisionalinstructors,
    groups.contentcoordinators,
    groups.researchercoordinators,
    groups.partnersupport
  ],
  assetStatusColors: {
    active: 'success',
    archive: 'danger'
  },
  report: {
    statuses: reportStatuses,
    types: reportTypes,
    typeLabels: reportTypeLabels
  },
  cardIssueStatusCodes,
  documentNameList,
  assetActTypes,
  inventoryTypes,
  inventoryTypeLabels,
  uploadTypes,
  reasonTargets,
  reasonTargetLabels,
  partnerRoles,
  updateDocFormNames,
  smzRequiredActions,
  smzStatusCodes,
  smzErrorCodes,
  shopperStatuses,
  bddStatuses,
  bddStatusesAct,
  env: {
    development: 'development',
    stage: 'stage',
    localhost: 'localhost'
  },
  registrationTypes,
  registrationTypeLabels,
  uploadFileStatuses,
  phoneEditErrorCodes,
  partnerStatTypes,
  urlRoleCodes,
  formScreenActions,
  sberId,
  tinkoffId,
  coordinatorTakePriority
}
