import { api, getLogHeaders, queryClient } from './api'
import { useQuery, useMutation } from 'react-query'
import { AppConfig } from '../../AppConfig'
import { USER_PROFILE_KEY } from './users'

export const ADMIN_PARTNERS_KEY = 'admin/partners'
export const ADMIN_PARTNERS_CONTENT_KEY = 'admin/partnersContent'
export const ADMIN_PARTNERS_RESEARCHER_KEY = 'admin/partnersResearcher'
export const ADMIN_BANNED_PARTNERS_KEY = `${ADMIN_PARTNERS_KEY}/list/banned`
export const ADMIN_PARTNER_KEY = 'partner'
export const ADMIN_PARTNER_ASSETS_KEY = 'partnerAssets'
export const ADMIN_PARTNER_ACTS_KEY = 'partnerActs'
export const ADMIN_PARTNER_STATUS_HISTORY_KEY = 'partnerStatusesHistory'
export const ADMIN_PARTNER_PROFILE_HISTORY_KEY = 'partnerProfileHistory'
export const ADMIN_PARTNER_OFFERS_KEY = 'offers'
export const ADMIN_CARD_FORM_KEY = 'cardForm'
export const ADMIN_LMK_FORM_KEY = 'lmkForm'
export const ADMIN_REQUISITES_FORM_KEY = 'requisitesForm'
export const ADMIN_EMPLOYMENT_TYPE_FORM_KEY = 'employmentTypeForm'
export const ADMIN_PROFILE_FORM_KEY = 'profileForm'
export const ADMIN_FIRE_FORM_KEY = 'fireForm'
export const SE_VERIFICATION_KEY = 'severification'

const defaultMutationOnSuccessById = (data, { id }) => {
  if (data.isSuccess) {
    queryClient.refetchQueries(ADMIN_PARTNERS_KEY)
    queryClient.refetchQueries(ADMIN_PARTNERS_CONTENT_KEY)
    queryClient.refetchQueries(ADMIN_PARTNERS_RESEARCHER_KEY)
    queryClient.refetchQueries(ADMIN_BANNED_PARTNERS_KEY)
    queryClient.resetQueries([ADMIN_PARTNER_KEY, id])
    queryClient.refetchQueries([ADMIN_PARTNER_ASSETS_KEY, id], { active: true })
    queryClient.refetchQueries([ADMIN_PARTNER_ACTS_KEY, id], { active: true })
    // queryClient.refetchQueries([ADMIN_LMK_FORM_KEY, id], { active: true })
    // queryClient.refetchQueries([ADMIN_EMPLOYMENT_TYPE_FORM_KEY, id], { active: true })
    queryClient.refetchQueries([ADMIN_PROFILE_FORM_KEY, id, 'nolog'], { active: true })
    // queryClient.refetchQueries([ADMIN_FIRE_FORM_KEY, id], { active: true })
  }
}
// not work.
// const onSuccessSEVerification = (data, id) => {
//   if (data.isSuccess) {
//     queryClient.cancelQueries([ADMIN_PARTNER_KEY, id])
//     queryClient.setQueryData([ADMIN_PARTNER_KEY, id], data)
//   }
// }
const fetchAdminPartnerById = ({ queryKey }, logHeader) => {
  const [, /* key */ id] = queryKey
  return api.get({
    path: `${ADMIN_PARTNERS_KEY}/${id}`,
    ...(logHeader ? { headers: getLogHeaders(logHeader) } : {})
  })
}
const fetchAdminPartnerProfileFormById = ({ queryKey }) => {
  const [, /* key */ id, refetchProfileInfo] = queryKey
  const noLog = refetchProfileInfo ? '?nolog=true' : ''
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/profile/form${noLog}` })
}
const fetchAdminPartnersList = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/list`, data: pagination })
}
const fetchAdminPartnersContentList = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  return api.post({
    path: `${ADMIN_PARTNERS_KEY}/list/${AppConfig.partnerRoles.szContent}`,
    data: pagination
  })
}
const fetchAdminPartnersResearcherList = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  return api.post({
    path: `${ADMIN_PARTNERS_KEY}/list/${AppConfig.partnerRoles.researcher}`,
    data: pagination
  })
}
const fetchAdminBannedPartnersList = ({ queryKey }) => {
  const [, /* key */ pagination] = queryKey
  return api.post({ path: ADMIN_BANNED_PARTNERS_KEY, data: pagination })
}
const fetchAdminUpdatePartnerPersonalData = ({ id, data }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/personalData`, data })
}
const fetchAdminPartnerYoudoRestart = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/youdo/restart` })
}
const fetchAdminUpdatePartnerBankDetails = ({ id, data }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/bankDetails`, data })
}
const fetchAdminUpdatePartnerInnDetails = ({ id, data }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/innDetails`, data })
}
const fetchAdminUpdateShopperStatus = ({ id, status, data = undefined }) => {
  const requestData = data || {}
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/shopper/${status}`, data: requestData })
}
const fetchAdminUpdateBddStatus = ({ id, status, data = undefined }) => {
  const requestData = data || {}
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/bdd/${status}`, data: requestData })
}
const fetchAdminArchivePartner = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/archive` })
}
const fetchAdminTerminatePartner = ({ id, reason = '' }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/ban`, data: { reason } })
}
const fetchAdminUnBanPartner = ({ id, reason = '' }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/unban`, data: { reason } })
}
const fetchAdminPartnerVerification = () => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/verification/take` })
}
const fetchAdminNewPartnerVerification = () => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/verification/take/new` })
}
const fetchAdminPartnerTake = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/forcetake`, data })
}
const fetchAdminPartnerUntake = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/forceunlock` })
}
const fetchAdminPartnerVerificationFormAndStatus = () => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/verification/getFormAndStatus` })
}
const fetchAdminPartnerVerificationFree = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/verification/free` })
}
const fetchAdminUpdatePartnerVerificationStatus = ({ id, status }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/verificationStatus`, data: { status } })
}
const fetchAdminUpdatePartnerKind = ({ id, kind }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/kind`, data: { kind } })
}
const fetchAdminUpdatePartnerAction = ({ id, type, action }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/${type}/${action}` })
}
const fetchAdminUpdatePartnerSecurityStatus = ({ id, data }) => {
  return api.put({ path: `${ADMIN_PARTNERS_KEY}/${id}/securityCheck`, data })
}
const fetchAdminUpdatePartnerDocs = ({ id, outcome, body }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/doc/${outcome}`, data: body })
}
const fetchAdminUpdatePartnerFormDocs = ({ id, formName, body }) => {
  return api.patch({ path: `${ADMIN_PARTNERS_KEY}/${id}/${formName}/doc/edit`, data: body })
}
const fetchAdminUpdateTerminateOffer = ({ id, reason }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/terminate`, data: { reason } })
}
const fetchAdminPartnerVerified = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/verified` })
}
const fetchPartnerAssets = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/assets` })
}
const fetchAdminPartnerActPreview = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/act/preview`, data })
}
const fetchAdminPartnerSendAct = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/sendAct`, data })
}
const fetchAdminPartnerActForce = ({ id, actId }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/act/${actId}/force` })
}
const fetchAdminPartnerAct = ({ id, data }) => {
  return api.put({ path: `${ADMIN_PARTNERS_KEY}/${id}/act`, data })
}
const fetchAdminPartnerActText = ({ id, actId }) => {
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/act/${actId}/text` })
}
const fetchAdminPartnerActs = ({ queryKey }) => {
  const [, /* key */ id, params] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/acts`, params })
}
const fetchAdminPartnerStatusHistory = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/statuses/history` })
}
const fetchAdminPartnerProfileHistory = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/profile/history` })
}
const fetchAdminPartnerOffers = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/${ADMIN_PARTNER_OFFERS_KEY}` })
}
const acceptEditInfoRequest = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/profile/form/accept`, data })
}
const approveEditInfoRequest = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/profile/form/approve`, data })
}
const declineEditInfoRequest = ({ id, reason }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/profile/form/decline`, data: { reason } })
}
const fetchCardForm = ({ partnerId, isRefetching }) => {
  const noLog = isRefetching ? '?nolog=true' : ''
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${partnerId}/card/form${noLog}` })
}
const fetchLmkForm = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/lmk/form` })
}
const fetchRequisitesForm = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/bankrequisite/form` })
}
const fetchEmploymentTypeForm = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/employmentType/form` })
}
const fetchFireBriefingForm = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${id}/fireBriefing/form` })
}
const acceptCardForm = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/card/form/accept`, data })
}
const declineCardForm = ({ id, reason }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/card/form/decline`, data: { reason } })
}
const acceptLmkForm = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/lmk/form/accept`, data })
}
const acceptRequisitesForm = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/bankrequisite/accept`, data })
}
const acceptEmploymentTypeForm = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/employmentType/form/accept`, data })
}
const acceptFireBriefingForm = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/fireBriefing/form/accept`, data })
}
const declineLmkForm = ({ id, reason }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/lmk/form/decline`, data: { reason } })
}
const declineRequisitesForm = ({ id, reason, description }) => {
  return api.post({
    path: `${ADMIN_PARTNERS_KEY}/${id}/bankrequisite/decline`,
    data: { reason, description }
  })
}
const declineEmploymentTypeForm = ({ id, reason }) => {
  return api.post({
    path: `${ADMIN_PARTNERS_KEY}/${id}/employmentType/form/decline`,
    data: { reason }
  })
}
const declineFireBriefingForm = ({ id, reason }) => {
  return api.post({
    path: `${ADMIN_PARTNERS_KEY}/${id}/fireBriefing/form/decline`,
    data: { reason }
  })
}
const SEVerificationRetry = id => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/${SE_VERIFICATION_KEY}/retry` })
}
const SEVerificationAllow = ({ id, data = {} }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/${SE_VERIFICATION_KEY}/allow`, data })
}
const SEVerificationDeny = id => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/${SE_VERIFICATION_KEY}/deny` })
}
const editPhone = ({ id, mobilePhone, forceChange }) => {
  return api.patch({
    path: `${ADMIN_PARTNERS_KEY}/${id}/phone`,
    data: { mobilePhone, forceChange }
  })
}
const reissueCardAdmin = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/card/reissue`, data })
}
const returnCardAdmin = ({ id, data }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/card/form/create`, data })
}
const issueCardAdmin = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/card/issue` })
}
const restartSmz = ({ id }) => {
  return api.post({ path: `${ADMIN_PARTNERS_KEY}/${id}/smz/restart` })
}
const checkUuid = ({ phone }) => {
  return api.get({ path: `${ADMIN_PARTNERS_KEY}/${phone}/uuid` })
}
const sendErp = ({ id }) => {
  return api.post({ path: `erp/partner/${id}` })
}
export function useQueryAdminPartnersList(pagination, options) {
  return useQuery([ADMIN_PARTNERS_KEY, pagination], fetchAdminPartnersList, options)
}
export function useQueryAdminPartnersContentList(pagination, options) {
  return useQuery([ADMIN_PARTNERS_CONTENT_KEY, pagination], fetchAdminPartnersContentList, options)
}
export function useQueryAdminPartnersResearcherList(pagination, options) {
  return useQuery(
    [ADMIN_PARTNERS_RESEARCHER_KEY, pagination],
    fetchAdminPartnersResearcherList,
    options
  )
}
export function useQueryAdminBannedPartnersList(pagination, options) {
  return useQuery([ADMIN_BANNED_PARTNERS_KEY, pagination], fetchAdminBannedPartnersList, options)
}
export function useQueryAdminPartnerById({ partnerId, logHeader }, options) {
  return useQuery(
    [ADMIN_PARTNER_KEY, partnerId],
    data => fetchAdminPartnerById(data, logHeader),
    options
  )
}
export function useQueryAdminPartnerProfileFormById({ partnerId, refetchProfileInfo }, options) {
  const queryKey = refetchProfileInfo
    ? [ADMIN_PROFILE_FORM_KEY, partnerId, 'nolog']
    : [ADMIN_PROFILE_FORM_KEY, partnerId]
  return useQuery(queryKey, fetchAdminPartnerProfileFormById, options)
}
export function useQueryAdminPartnerAssets(id, options) {
  return useQuery([ADMIN_PARTNER_ASSETS_KEY, id], fetchPartnerAssets, options)
}
export function useQueryAdminPartnerActs({ id, params }, options) {
  return useQuery([ADMIN_PARTNER_ACTS_KEY, id, params], fetchAdminPartnerActs, options)
}
export function useQueryAdminPartnerStatusHistory(id, options) {
  return useQuery([ADMIN_PARTNER_STATUS_HISTORY_KEY, id], fetchAdminPartnerStatusHistory, options)
}
export function useQueryAdminPartnerProfileHistory(id, options) {
  return useQuery([ADMIN_PARTNER_PROFILE_HISTORY_KEY, id], fetchAdminPartnerProfileHistory, options)
}
export function useQueryAdminPartnerOffers(id, options) {
  return useQuery([ADMIN_PARTNER_OFFERS_KEY, id], fetchAdminPartnerOffers, options)
}
export function useMutateQueryAdminCardForm(options) {
  return useMutation(fetchCardForm, options)
}
export function useQueryAdminLmkForm(id, options) {
  return useQuery([ADMIN_LMK_FORM_KEY, id], fetchLmkForm, options)
}
export function useQueryAdminRequisitesForm(id, options) {
  return useQuery([ADMIN_REQUISITES_FORM_KEY, id], fetchRequisitesForm, options)
}
export function useQueryAdminEmploymentTypeForm(id, options) {
  return useQuery([ADMIN_EMPLOYMENT_TYPE_FORM_KEY, id], fetchEmploymentTypeForm, options)
}
export function useQueryAdminFireBriefingForm(id, options) {
  return useQuery([ADMIN_FIRE_FORM_KEY, id], fetchFireBriefingForm, options)
}
export function useMutateAdminUpdatePartnerPersonalData() {
  return useMutation(fetchAdminUpdatePartnerPersonalData, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerYoudoRestart() {
  return useMutation(fetchAdminPartnerYoudoRestart, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerBankDetails() {
  return useMutation(fetchAdminUpdatePartnerBankDetails, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerInnDetails() {
  return useMutation(fetchAdminUpdatePartnerInnDetails, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdateShopperStatus() {
  return useMutation(fetchAdminUpdateShopperStatus, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdateBddStatus() {
  return useMutation(fetchAdminUpdateBddStatus, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerVerificationStatus() {
  return useMutation(fetchAdminUpdatePartnerVerificationStatus, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminArchivePartner() {
  return useMutation(fetchAdminArchivePartner, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminTerminatePartner() {
  return useMutation(fetchAdminTerminatePartner, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUnBanPartner() {
  return useMutation(fetchAdminUnBanPartner, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerVerificationFree() {
  return useMutation(fetchAdminPartnerVerificationFree, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerVerification() {
  return useMutation(fetchAdminPartnerVerification)
}
export function useMutateAdminNewPartnerVerification() {
  return useMutation(fetchAdminNewPartnerVerification)
}
export function useMutateAdminPartnerTake() {
  return useMutation(fetchAdminPartnerTake, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerUntake() {
  return useMutation(fetchAdminPartnerUntake, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerVerificationFormAndStatus() {
  return useMutation(fetchAdminPartnerVerificationFormAndStatus, {
    onSuccess: queryData => {
      queryClient.setQueryData(USER_PROFILE_KEY, prevUserProfileData => {
        return {
          ...prevUserProfileData,
          user: { ...prevUserProfileData?.user, online: queryData?.isUserOnline }
        }
      })
    }
  })
}
export function useMutateAdminUpdatePartnerKind() {
  return useMutation(fetchAdminUpdatePartnerKind, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerAction() {
  return useMutation(fetchAdminUpdatePartnerAction, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerSecurityStatus() {
  return useMutation(fetchAdminUpdatePartnerSecurityStatus, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerDocs() {
  return useMutation(fetchAdminUpdatePartnerDocs, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdatePartnerFormDocs() {
  return useMutation(fetchAdminUpdatePartnerFormDocs, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminUpdateTerminateOffer() {
  return useMutation(fetchAdminUpdateTerminateOffer, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerVerified() {
  return useMutation(fetchAdminPartnerVerified, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerActPreview() {
  return useMutation(fetchAdminPartnerActPreview, {
    // onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerAct() {
  return useMutation(fetchAdminPartnerAct, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerActText() {
  return useMutation(fetchAdminPartnerActText)
}
export function useMutateAdminPartnerSendAct() {
  return useMutation(fetchAdminPartnerSendAct)
}
export function useMutateAdminPartnerActForce() {
  return useMutation(fetchAdminPartnerActForce, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerAcceptEditInfoRequest() {
  return useMutation(acceptEditInfoRequest, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerApproveEditInfoRequest() {
  return useMutation(approveEditInfoRequest, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminPartnerDeclineEditInfoRequest() {
  return useMutation(declineEditInfoRequest, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminAcceptCardForm() {
  return useMutation(acceptCardForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminDeclineCardForm() {
  return useMutation(declineCardForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminAcceptLmkForm() {
  return useMutation(acceptLmkForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminAcceptRequisitesForm() {
  return useMutation(acceptRequisitesForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminAcceptEmploymentTypeForm() {
  return useMutation(acceptEmploymentTypeForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminAcceptFireBriefingForm() {
  return useMutation(acceptFireBriefingForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminDeclineLmkForm() {
  return useMutation(declineLmkForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminDeclineRequisitesForm() {
  return useMutation(declineRequisitesForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminDeclineEmploymentTypeForm() {
  return useMutation(declineEmploymentTypeForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateAdminDeclineFireBriefingForm() {
  return useMutation(declineFireBriefingForm, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateSEVerificationRetry() {
  return useMutation(SEVerificationRetry, {
    onSuccess: defaultMutationOnSuccessById
    // onSuccess: onSuccessSEVerification not work
  })
}
export function useMutateSEVerificationAllow() {
  return useMutation(SEVerificationAllow, {
    onSuccess: defaultMutationOnSuccessById
    // onSuccess: onSuccessSEVerification not work
  })
}
export function useMutateSEVerificationDeny() {
  return useMutation(SEVerificationDeny, {
    onSuccess: defaultMutationOnSuccessById
    // onSuccess: onSuccessSEVerification not work
  })
}
export function useMutateEditPhone() {
  return useMutation(editPhone, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateReissueCardAdmin() {
  return useMutation(reissueCardAdmin, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateReturnCardAdmin() {
  return useMutation(returnCardAdmin, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateIssueCardAdmin() {
  return useMutation(issueCardAdmin, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateRestartSmz() {
  return useMutation(restartSmz, {
    onSuccess: defaultMutationOnSuccessById
  })
}
export function useMutateSendErp() {
  return useMutation(sendErp)
}
export function useMutateCheckUuid() {
  return useMutation(checkUuid)
}
