import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

export const DICT_KEY = 'dict'
export const STATUSES_KEY = 'statuses'

export const CITIZENSHIPS_KEY = 'citizenships'
export const ROLES_KEY = 'roles'
export const CLOTHING_SIZES_KEY = 'clothingSizes'

export const PARTNER_KEY = 'partner'
// export const SECURITY_KEY = 'securityCheck'
// export const YOUDO_KEY = 'youdo'
export const SMZ_KEY = 'smz'
export const LEGAL_FORM_KEY = 'legalForm'
export const ASSET_KEY = 'asset'
export const ACT_KEY = 'act'
export const DOCUMENT_KEY = 'document'
export const ACT_TEMPLATE_KEY = 'actTemplate'
export const TYPES_KEY = 'types'
export const VERIFICATION_KEY = 'verification'
export const PROFILE_FORM_KEY = 'profileForm'
export const LMK_KEY = 'lmk'
export const EMPLOYMENT_TYPE_KEY = 'employmentTypeForm'
export const FIRE_BRIEFING_KEY = 'fireBriefing'
export const DRIVING_CATEGORIES_KEY = 'drivingCategories'
export const CARD_KEY = 'card'
// export const REQUISITES_KEY = 'requisites'
export const REISSUE_REASONS_KEY = 'reissueReasons'
export const REISSUE_CLAIMS_KEY = 'reissueClaims'
// const CONDITIONS = 'conditions'

const DECLINE_REASONS_KEY = 'declineReasons'

const getRoles = () => {
  return api.get({ path: `${DICT_KEY}/${ROLES_KEY}` })
}
const getClothingSizes = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.get({
    path: `${DICT_KEY}/${CLOTHING_SIZES_KEY}${data?.token ? '?token=' + data.token : ''}`
  })
}
const getCitizenships = () => {
  return api.get({ path: `${DICT_KEY}/${CITIZENSHIPS_KEY}` })
}
const getStatuses = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  let path = `${DICT_KEY}/${STATUSES_KEY}/${PARTNER_KEY}`
  if (data?.intent) {
    path += '?intent=reporting'
  }
  return api.get({ path })
}
const getVerificationStatus = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${VERIFICATION_KEY}` })
}
// const getSecurityStatuses = () => {
//   return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${SECURITY_KEY}` })
// }
// const getYoudoStatuses = () => {
//   return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${YOUDO_KEY}` })
// }
const getSmzStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${SMZ_KEY}` })
}
const getLegalFormStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${LEGAL_FORM_KEY}` })
}
const getAssetStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${ASSET_KEY}` })
}
const getActTemplatesStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${ACT_TEMPLATE_KEY}` })
}
const getActTypes = () => {
  return api.get({ path: `${DICT_KEY}/${ACT_KEY}/${TYPES_KEY}` })
}
const getDocumentTypes = () => {
  return api.get({ path: `${DICT_KEY}/${DOCUMENT_KEY}/${TYPES_KEY}` })
}
const getFormStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${STATUSES_KEY}/${PROFILE_FORM_KEY}` })
}
const getLmkFormStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${LMK_KEY}/${STATUSES_KEY}` })
}
const getEmploymentTypeFormStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${EMPLOYMENT_TYPE_KEY}/${STATUSES_KEY}` })
}
const getFireBriefingFormStatuses = () => {
  return api.get({ path: `${DICT_KEY}/${FIRE_BRIEFING_KEY}/${STATUSES_KEY}` })
}
const getDrivingCategories = () => {
  return api.get({ path: `${DICT_KEY}/${DRIVING_CATEGORIES_KEY}` })
}
const getCardReissueReasons = () => {
  return api.get({ path: `${DICT_KEY}/${CARD_KEY}/${REISSUE_REASONS_KEY}` })
}
const getCardReissueClaims = () => {
  return api.get({ path: `${DICT_KEY}/${CARD_KEY}/${REISSUE_CLAIMS_KEY}` })
}
// const getCardFormConditions = () => {
//   return api.get({ path: `${DICT_KEY}/${CARD_KEY}/form/${CONDITIONS}` })
// }
// const getCardStatuses = () => {
//   return api.get({ path: `${DICT_KEY}/card/${STATUSES_KEY}` })
// }
// const getRequisitesStatuses = () => {
//   return api.get({ path: `${DICT_KEY}/bankRequisite/${STATUSES_KEY}` })
// }
const createDeclineReason = data => {
  return api.post({ path: `${DICT_KEY}/${DECLINE_REASONS_KEY}`, data })
}
const getDeclineReasons = ({ queryKey }) => {
  const [, /* key */ target] = queryKey
  const queryString = target ? `?target=${target}` : ''
  return api.get({ path: `${DICT_KEY}/${DECLINE_REASONS_KEY}${queryString}` })
}
const deleteDeclineReason = id => {
  return api.del({ path: `${DICT_KEY}/${DECLINE_REASONS_KEY}/${id}` })
}
const editDeclineReason = ({ id, data }) => {
  return api.patch({ path: `${DICT_KEY}/${DECLINE_REASONS_KEY}/${id}`, data })
}

export function useRefCitizenshipQuery(data, options) {
  return useQuery([CITIZENSHIPS_KEY, data], getCitizenships, options)
}
export function usePartnerStatusesQuery(data, options) {
  return useQuery([STATUSES_KEY, data], getStatuses, options)
}
export function usePartnerVerificationStatusesQuery(options) {
  return useQuery(VERIFICATION_KEY, getVerificationStatus, options)
}
// export function usePartnerSecurityStatusesQuery (options) {
//   return useQuery(SECURITY_KEY, getSecurityStatuses, options)
// }
// export function usePartnerYoudoStatusesQuery (options) {
//   return useQuery(YOUDO_KEY, getYoudoStatuses, options)
// }
export function usePartnerSmzStatusesQuery(options) {
  return useQuery(SMZ_KEY, getSmzStatuses, options)
}
export function usePartnerLegalFormStatusesQuery(options) {
  return useQuery(LEGAL_FORM_KEY, getLegalFormStatuses, options)
}
export function useAssetStatusesQuery(options) {
  return useQuery(ASSET_KEY, getAssetStatuses, options)
}
export function useActTemplatesStatusesQuery(options) {
  return useQuery(ACT_TEMPLATE_KEY, getActTemplatesStatuses, options)
}
export function useActTypesQuery(options) {
  return useQuery(TYPES_KEY, getActTypes, options)
}
export function useDocumentTypesQuery(options) {
  return useQuery(`${DOCUMENT_KEY}/${TYPES_KEY}`, getDocumentTypes, options)
}
export function usePartnerRolesQuery(data, options) {
  return useQuery(ROLES_KEY, getRoles, options)
}
export function usePartnerClothingSizesQuery(data, options) {
  return useQuery([CLOTHING_SIZES_KEY, data], getClothingSizes, options)
}
export function usePartnerFormStatusesQuery(options) {
  return useQuery(PROFILE_FORM_KEY, getFormStatuses, options)
}
export function usePartnerLmkFormStatusesQuery(options) {
  return useQuery(LMK_KEY, getLmkFormStatuses, options)
}
export function usePartnerEmploymentTypeFormStatusesQuery(options) {
  return useQuery(EMPLOYMENT_TYPE_KEY, getEmploymentTypeFormStatuses, options)
}
export function usePartnerFireBriefingFormStatusesQuery(options) {
  return useQuery(FIRE_BRIEFING_KEY, getFireBriefingFormStatuses, options)
}
export function useDrivingCategoriesQuery(options) {
  return useQuery(FIRE_BRIEFING_KEY, getDrivingCategories, options)
}
export function usePartnerCardReissueReasonsQuery(options) {
  return useQuery(`${CARD_KEY}/${REISSUE_REASONS_KEY}`, getCardReissueReasons, options)
}
export function usePartnerCardReissueClaimsQuery(options) {
  return useQuery(`${CARD_KEY}/${REISSUE_CLAIMS_KEY}`, getCardReissueClaims, options)
}
// export function usePartnerCardFormConditionsQuery (options) {
//   return useQuery(`${CARD_KEY}/${CONDITIONS}`, getCardFormConditions, options)
// }
// export function usePartnerCardStatusesQuery (options) {
//   return useQuery(`${CARD_KEY}/${STATUSES_KEY}`, getCardStatuses, options)
// }
// export function usePartnerRequisitesStatusesQuery (options) {
//   return useQuery(`${REQUISITES_KEY}/${STATUSES_KEY}`, getRequisitesStatuses, options)
// }
export function useMutateDeclineReasonCreate() {
  return useMutation(createDeclineReason, {
    onSuccess: () => {
      queryClient.refetchQueries(`${DICT_KEY}/${DECLINE_REASONS_KEY}`)
    }
  })
}
export function useDeclineReasonsQuery(target, options) {
  return useQuery([`${DICT_KEY}/${DECLINE_REASONS_KEY}`, target], getDeclineReasons, options)
}
export function useMutateDeclineReasonDelete() {
  return useMutation(deleteDeclineReason, {
    onSuccess: () => {
      queryClient.refetchQueries(`${DICT_KEY}/${DECLINE_REASONS_KEY}`)
    }
  })
}
export function useMutateDeclineReasonEdit() {
  return useMutation(editDeclineReason, {
    onSuccess: () => {
      queryClient.refetchQueries(`${DICT_KEY}/${DECLINE_REASONS_KEY}`)
    }
  })
}
